import * as React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";

const SectionContainerStyle = {
  margin: 0,
  textAlign: "center",
  height: "50px",
};
const LinkStyle = {
  textDecoration: "none",
  color: "darkgrey",
  fontWeight: "250",
  lineHeight: "50px",
  cursor: "pointer",
};

const ImpressumSection = () => {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <div style={SectionContainerStyle}>
      <Link
        style={isHover ? { ...LinkStyle, ...{ color: "black" } } : LinkStyle}
        to={"/impressum"}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        Impressum
      </Link>
    </div>
  );
};

export default ImpressumSection;
