import * as React from "react";
import HeaderSection from "./elements/HeaderSection.js";
import ImpressumSection from "./elements/ImpressumSection.js";
import * as Content from "./Content.js";

const pageStyles = {
  fontFamily: "Gill Sans",
};
const ImpressumContainerStyle = {
  width: window.innerWidth > 800 ? "50vw" : "75vw",
  margin: window.innerWidth > 800 ? "5% auto" : "20% auto",
  fontSize: "1.1rem",
  fontWeight: "200",
};
const TitleStyle = {
  fontSize: "2rem",
  fontWeight: "400",
  fontFamily: "Gill Sans",
};

/*
Name, Vorname, Anschrift, E-Mail, 
Rechtsform, Vertretungsberechtigten, Umsatzsteuer-ID,
 Schlichtungsstellen ? 
*/

const ServicesPage = () => {
  return (
    <main style={pageStyles}>
      <HeaderSection
        links={{
          pageLinks: Content.links,
          personalLinks: Content.personalLinks,
        }}
      ></HeaderSection>

      <div style={ImpressumContainerStyle}>
        <h2 style={TitleStyle}>Impressum</h2>
        <br></br>
        <p>Diese Seite wird betrieben von</p>
        <br></br>
        <p>Friedrichs, Stefanie - Einzelunternehmen</p>
        <p>Moltkestraße 5a</p>
        <p>32427 Minden</p>
        <br></br>
        <p>USt-IdNr. DE348634523</p>
        <br></br>
        <p>Das Unternehmen wird vertreten durch: Stefanie Friedrichs</p>
        <p>Kontakt: steffriedrichs.dev@googlemail.com</p>
        <br></br>
        <p>Alle Angaben ohne Gewähr.</p>
      </div>

      <ImpressumSection></ImpressumSection>
    </main>
  );
};

export default ServicesPage;
