import * as React from "react";
import { useState } from "react";

const SectionContainerStyle = {
  margin: "auto",
  fontFamily: "Gill Sans",
  display: "flex",
  width: "75%",
  justifyContent: "center",
  flexDirection: "row",
  flexWrap: "wrap",
  marginBottom: "10%",
};
const TitleStyle = {
  fontSize: "2rem",
  textTransform: "uppercase",
  fontWeight: "400",
  fontFamily: "Gill Sans",
  textAlign: "center",
};
const IconStyle = {
  backgroundSize: "100%",
  borderRadius: "30px",
  height: "150px",
  aspectRatio: "1 / 1",
  margin: "15px",
};
const HoverStyle = {
  width: "100%",
  height: "100%",
  marginTop: "-20px",
  borderRadius: "30px",
  backgroundColor: "rgba(0,0,0,0.5)",
  color: "white",
};
const TitleTextStyle = {
  paddingTop: "20px",
  fontFamily: "Gill Sans",
  textAlign: "center",
  fontSize: "20px",
  fontWeight: "600",
};
const TextStyle = {
  fontFamily: "Source Sans Pro, sans-serif",
  textAlign: "center",
  fontSize: "16px",
  color: "white",
};
const LinkStyle = {
  cursor: "pointer",
  textDecoration: "none",
  fontSize: "14px",
  lineHeight: "30px",
};
const ButtonBorder = {
  padding: "2px 5px",
  border: "2px solid #ffffff",
};

function Portfolio({ content }) {
  const [isHover, setIsHover] = useState(0);

  const handleMouseEnter = (id) => {
    setIsHover(id);
  };
  const handleMouseLeave = () => {
    setIsHover(0);
  };

  return (
    <div style={{ marginTop: "10%" }}>
      <h2 style={TitleStyle}>Projekte</h2>
      <div style={SectionContainerStyle}>
        {content.map((item) => (
          <div
            key={item.id}
            style={
              isHover === item.id
                ? {
                    ...IconStyle,
                    ...{ backgroundImage: item.icon },
                  }
                : {
                    ...IconStyle,
                    ...{ backgroundImage: item.icon },
                  }
            }
            onMouseEnter={() => handleMouseEnter(item.id)}
            onMouseLeave={handleMouseLeave}
          >
            <div style={isHover === item.id ? HoverStyle : { display: "none" }}>
              <p style={TitleTextStyle}>{item.name}</p>
              <p style={TextStyle}>{item.tech}</p>
              <p style={TextStyle}>
                <a
                  style={{ ...TextStyle, ...LinkStyle }}
                  href={item.link}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  <span style={ButtonBorder}>{item.button}</span>
                </a>
                {item.gitLink ? (
                  <a
                    style={{ ...TextStyle, ...LinkStyle }}
                    href={item.gitLink}
                    target={"_blank"}
                    rel={"noreferrer"}
                  >
                    <span style={ButtonBorder}>{item.gitButton}</span>
                  </a>
                ) : (
                  <></>
                )}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Portfolio;
