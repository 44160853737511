import React from "react";

const cookiesPopupContainer = {
  width: "100%",
  height: "fit-content",
  marginBottom: 0,
  position: "fixed",
  background: "#282848",
  left: "0%",
  right: "0%",
  bottom: "0%",
  margin: "0",
};
const TitleTextStyle = {
  fontSize: "1.25rem",
  fontWeight: "300",
  fontFamily: "Gill Sans",
  textAlign: "center",
  color: "white",
};
const cookiesButton = {
  margin: "5px 0",
  borderColor: "white",
  borderWidth: "1px",
  borderStyle: "solid",
  backgroundColor: "#282848",
  padding: "8px 10px",
  borderRadius: "5px",
  cursor: "pointer",
  outline: "none",
  color: "white",
};
const buttonPaddingStyle = {
  paddingLeft: "5%",
};

function CookiesBanner(props) {
  return (
    <div style={cookiesPopupContainer}>
      <p style={TitleTextStyle}>
        Diese Webseite verwendet technisch notwendige Cookies.
        <span style={buttonPaddingStyle}>
          <button style={cookiesButton} onClick={props.handleCookiesAccept}>
            Einverstanden
          </button>
        </span>
      </p>
    </div>
  );
}

export default CookiesBanner;
