import * as React from "react";

const SectionContainerStyle = {
  margin: 0,
  textAlign: "center",
  flexDirection: "row",
  alignItems: "center",
};
const SectionSubContainerStyle = {
  textAlign: "center",
  margin: "auto",
};
const TitleStyle = {
  marginTop: 50,
  marginBottom: 30,
  fontSize: "2rem",
  textTransform: "uppercase",
  fontWeight: "400",
  fontFamily: "Gill Sans",
};
const TextStyle = {
  margin: "auto",
  marginTop: 0,
  marginBottom: "1.5em",
  width: "80vw",
  fontSize: "17px",
  fontWeight: "400",
  lineHeight: "1.75",
  fontFamily: "Source Sans Pro, sans-serif",
};
const BoxenContainerStyle = {
  textAlign: "center",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: window.innerWidth > 800 ? "start" : "center",
  flexDirection: window.innerWidth > 800 ? "row" : "column",
};
const BoxStyle = {
  margin: 50,
  width: window.innerWidth > 800 ? "27vw" : "75vw",
  paddingStart: "10px",
};
const BoxTitleStyle = {
  color: "#C1AA89",
  fontSize: "22px",
  marginTop: 50,
  marginBottom: 30,
  fontWeight: "400",
  fontFamily: "Source Sans Pro, sans-serif",
  textAlign: "center",
};
const BoxTextStyle = {
  marginBottom: "1.5em",
  fontSize: "18px",
  fontWeight: "400",
  lineHeight: "1.5",
  fontFamily: "Source Sans Pro, sans-serif",
  textAlign: "justify",
};
const textContainer = {
  width: "70%",
  margin: "auto",
};
const PictureStyle = {
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  width: "100%",
  paddingTop: "55%",
};

function NavButtonBoxes({ boxItems }) {
  return (
    <div style={SectionContainerStyle}>
      <div style={SectionSubContainerStyle}>
        <h2 style={TitleStyle}>Services</h2>
        <p style={TextStyle}>
          Ich kann SQL Abfragen schreiben und APIs integrieren 
          <br></br>meine Hauptexpertise und Leidenschaft liegen aber im Frontend.
        </p>
      </div>
      <div style={BoxenContainerStyle}>
        {boxItems.map((box) => (
          <div style={BoxStyle}>
            <div style={textContainer}>
              <div
                style={{ ...PictureStyle, ...{ backgroundImage: box.picture } }}
              ></div>
              <p style={BoxTitleStyle}>{box.title}</p>
              <p style={BoxTextStyle}>{box.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default NavButtonBoxes;
