import HeaderSection from "./elements/HeaderSection.js";
import CookiesBanner from "./elements/CookiesBanner.js";
import IntroSection from "./elements/IntroSection.js";
import Portfolio from "./elements/Portfolio.js";
import ImpressumSection from "./elements/ImpressumSection.js";
import * as Content from "./Content";
import { useRef, useState } from "react";

const AppStyle = {
  color: "#232129",
  fontFamily: "Gill Sans, sans-serif, serif, -apple-system, Roboto,",
  lineHeight: "1.5",
};

function App() {
  const ref = useRef(null);
  const [cookiesAccepted, setcookiesAccepted] = useState(false);

  let cookiesPopupSeen = localStorage.getItem("cookies");
  const handleCookiesAccept = () => {
    localStorage.setItem("cookies", 1);
    setcookiesAccepted(true);
  };

  function handleScrolling(location) {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <div style={AppStyle}>
      <HeaderSection
        links={{
          pageLinks: Content.links,
          personalLinks: Content.personalLinks,
        }}
      ></HeaderSection>

      {!cookiesAccepted && cookiesPopupSeen !== "1" ? (
        <CookiesBanner
          handleCookiesAccept={handleCookiesAccept}
        ></CookiesBanner>
      ) : null}

      <IntroSection
        clickHandler={handleScrolling}
        content={Content.intro}
      ></IntroSection>

      <span ref={ref}>
        <Portfolio content={Content.portfolioItems}></Portfolio>
      </span>

      <ImpressumSection></ImpressumSection>
    </div>
  );
}

export default App;
