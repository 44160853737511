import * as React from "react";

const SectionContainerStyle = {
  textAlign: "center",
  fontFamily: "Gill Sans",
  margin: "auto",
  maxWidth: window.innerWidth > 800 ? "80vw" : "100vw",
  display: "grid",
  gridTemplateColumns: "auto 2px auto",
};
const TitleStyle = {
  fontSize: "2rem",
  textTransform: "uppercase",
  fontWeight: "400",
  fontFamily: "Gill Sans",
  textAlign: "center",
  marginBottom: "5%",
};
const LeftContainerStyle = {
  textAlign: "left",
  display: "grid",
  gridTemplateColumns: "35% auto 10%",
  paddingTop: "10px",
  paddingBottom: "20px",
};
const RightContainerStyle = {
  textAlign: "left",
  display: "grid",
  gridTemplateColumns: "10% auto 35%",
  paddingTop: "10px",
  paddingBottom: "20px",
};
const ItemTitleStyle = {
  color: "#C1AA89",
  fontSize: "1.35rem",
  fontWeight: "400",
  fontFamily: "Gill Sans",
  margin: "5px 0",
};
const TextStyle = {
  fontSize: "17px",
  fontWeight: "400",
  lineHeight: "1.75",
  fontFamily: "Source Sans Pro, sans-serif",
  margin: "5px 0",
};
const TechListStyle = {
  fontWeight: "400",
  color: "darkgrey",
  fontSize: "1.25rem",
  margin: "20px",
};
const horizontalLineContainer = {
  display: "flex",
  alignItems: "center",
};
const horizontalLineStyle = {
  borderBottom: "solid black 3px",
  width: "100%",
};

function Timeline({ list }) {
  return (
    <div style={{ margin: "7% 0" }}>
      <h2 style={TitleStyle}>Skills & Experience</h2>
      <div style={SectionContainerStyle}>
        <div>
          {list.list.map((item) => (
            <div
              style={
                item.id % 2 === 0
                  ? { ...LeftContainerStyle, ...{ opacity: "0" } }
                  : { ...LeftContainerStyle, ...{ opacity: "1" } }
              }
            >
              <div style={horizontalLineContainer}>
                <p style={TechListStyle}>{item.tech}</p>
              </div>
              <div
                style={{
                  paddingRight: "5%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div>
                  <p style={ItemTitleStyle}>{item.title}</p>
                  <p style={TextStyle}>{item.text}</p>
                </div>
              </div>
              <div style={horizontalLineContainer}>
                <div style={horizontalLineStyle}></div>
              </div>
            </div>
          ))}
        </div>

        <div style={{ borderRight: "solid black 3px" }}></div>

        <div>
          {list.list.map((item) => (
            <div
              style={
                item.id % 2 === 1
                  ? { ...RightContainerStyle, ...{ opacity: "0" } }
                  : { ...RightContainerStyle, ...{ opacity: "1" } }
              }
            >
              <div style={horizontalLineContainer}>
                <div style={horizontalLineStyle}></div>
              </div>
              <div
                style={{
                  paddingLeft: "5%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div>
                  <p style={ItemTitleStyle}>{item.title}</p>
                  <p style={TextStyle}>{item.text}</p>
                </div>
              </div>
              <div style={horizontalLineContainer}>
                <p style={TechListStyle}>{item.tech}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Timeline;
