import * as React from "react";

const SectionContainerStyle = {
  textAlign: "center",
  fontFamily: "Gill Sans",
  width: "80vw",
  margin: "auto",
};
const RightContainerStyle = {
  textAlign: "left",
  display: "grid",
  gridTemplateColumns: "50% auto",
  paddingTop: "10px",
  paddingBottom: "10px",
};
const TitleStyle = {
  marginBottom: 50,
  marginTop: 50,
  fontSize: "1.8rem",
  textTransform: "uppercase",
  fontWeight: "400",
  fontFamily: "Gill Sans",
  textAlign: "center",
};
const ItemTitleStyle = {
  color: "#C1AA89",
  fontSize: "1.2rem",
  fontWeight: "400",
  fontFamily: "Gill Sans",
  margin: "5px 0",
};
const TextStyle = {
  fontWeight: "400",
  lineHeight: "1.5",
  fontFamily: "Source Sans Pro, sans-serif",
  margin: "5px 0",
};
const TechListStyle = {
  fontWeight: "400",
  color: "darkgrey",
  fontSize: "1.1rem",
  margin: "20px",
};
const horizontalLineContainer = {
  display: "flex",
  alignItems: "center",
};

function ListSection({ list }) {
  return (
    <div style={{ margin: "7% 0" }}>
      <h2 style={TitleStyle}>Skills & Experience</h2>
      <div style={SectionContainerStyle}>
        <div>
          {list.list.map((item) => (
            <>
              <div style={RightContainerStyle}>
                <div style={horizontalLineContainer}>
                  <p style={TechListStyle}>{item.tech}</p>
                </div>
                <div
                  style={{
                    paddingLeft: "5%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <p style={ItemTitleStyle}>{item.title}</p>
                    <p style={TextStyle}>{item.text}</p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  borderLeft: "solid black 2px",
                  height: "20px",
                  margin: "3% 0",
                  marginLeft: "50%",
                  display: item.id === 6 ? "none" : "",
                }}
              ></div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ListSection;
