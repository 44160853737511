import * as React from "react";
import { useState } from "react";
import HeaderSection from "./elements/HeaderSection.js";
import CookiesBanner from "./elements/CookiesBanner.js";
import ImpressumSection from "./elements/ImpressumSection.js";
import Timeline from "./elements/Timeline.js";
import ListSection from "./elements/ListSection.js";
import * as Content from "./Content";

const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};

const AboutPage = () => {
  const [cookiesAccepted, setcookiesAccepted] = useState(false);

  let cookiesPopupSeen = localStorage.getItem("cookies");
  const handleCookiesAccept = () => {
    localStorage.setItem("cookies", 1);
    setcookiesAccepted(true);
  };

  return (
    <main style={pageStyles}>
      <HeaderSection
        links={{
          pageLinks: Content.links,
          personalLinks: Content.personalLinks,
        }}
      ></HeaderSection>

      {!cookiesAccepted && cookiesPopupSeen !== "1" ? (
        <CookiesBanner
          handleCookiesAccept={handleCookiesAccept}
        ></CookiesBanner>
      ) : null}

      {window.innerWidth > 900 ? (
        <Timeline list={{ list: Content.listSkills }}></Timeline>
      ) : (
        <ListSection list={{ list: Content.listSkills }}></ListSection>
      )}

      <ImpressumSection></ImpressumSection>
    </main>
  );
};

export default AboutPage;
