import * as React from "react";

const SectionContainerStyle = {
  margin: "5% auto",
  fontFamily: "Gill Sans",
  display: "flex",
  justifyContent: "center",
  width: window.innerWidth > 900 ? "50%" : "70%",
  flexDirection: window.innerWidth > 900 ? "row" : "column",
};
const SubContainerStyle = {
  width: window.innerWidth > 900 ? "50%" : "100%",
  selfAlign: "center",
};
const PictureStyle = {
  height: window.innerWidth > 900 ? "80%" : "50vw",
  margin: window.innerWidth > 900 ? "0" : "10% auto",
  aspectRatio: "1 / 1",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
};
const TitleTextStyle = {
  fontSize: "2rem",
  textTransform: "uppercase",
  fontWeight: "400",
  fontFamily: "Gill Sans",
  textAlign: "center",
};
const textStyle = {
  fontSize: "1.1rem",
  lineHeight: "1.5",
  fontFamily: "Source Sans Pro, sans-serif",
  textAlign: "justify",
};

function IntroSection({ content, clickHandler }) {
  return (
    <div style={SectionContainerStyle}>
      <div style={SubContainerStyle}>
        <div
          style={{
            ...PictureStyle,
            ...{ backgroundImage: content.picture },
          }}
        ></div>
      </div>
      <div style={SubContainerStyle}>
        <h2 style={TitleTextStyle}>{content.title}</h2>
        <p style={textStyle}>
          {content.text}{" "}
          <span
            style={{ ...textStyle, ...{ cursor: "pointer" } }}
            onClick={clickHandler}
          >
            Portfolio.
          </span>
        </p>
      </div>
    </div>
  );
}

export default IntroSection;
