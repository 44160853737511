export const links = [
  { id: 1, text: "Home", to: "/" },
  { id: 2, text: "About", to: "/about" },
  { id: 3, text: "Services", to: "/services" },
];

/*
logo use: https://github.com/logos, https://brand.linkedin.com/policies, https://www.freelance.de/blog/profil-button-fuer-ihr-freelancer-profil/
*/

export const personalLinks = [
  {
    id: 11,
    logo: "url(../github-mark.png)",
    text: "GitHub",
    link: "https://github.com/steffriedrichs",
  },
  {
    id: 12,
    logo: "url(../fl-profil-button.jpg)",
    text: "freelance.de",
    link: "https://www.freelance.de/Freelancer/258040-Kotlin-Android-React-JavaScript-Frontend-Developer",
  },
  {
    id: 13,
    logo: "url(../In-Blue-Logo.png)",
    text: "LinkedIN",
    link: "https://www.linkedin.com/in/stefanie-friedrichs",
  },
];

/*
    "Hallo, ich bin Steffi, selbstständige Entwicklerin mit dem Schwerpunkt Frontend Programmierung in React und Kotlin. Ich habe mehrere Jahre Erfahrung in der Entwicklung von ansprechenden, benutzerfreundlichen und funktionalen Webseiten und nativen Android Apps für verschiedene Anwendungsbereiche. Ich bin immer auf der Suche nach neuen Herausforderungen und Möglichkeiten, meine Fähigkeiten zu erweitern und zu verbessern. Wenn Sie an einer Zusammenarbeit interessiert sind oder mehr über meine Projekte erfahren möchten, kontaktieren Sie mich gerne oder besuchen Sie mein Portfolio. Ich freue mich, von Ihnen zu hören!",
*/
export const intro = {
  title: "Willkommen",
  text: "Als Entwicklerin für Kotlin und React mit Leidenschaft für Frontend suche ich immer neue Möglichkeiten meine Fähigkeiten zu erweitern und zu verbessern. Wenn Sie mehr über mich oder meine Projekte erfahren möchten, kontaktieren Sie mich gerne oder besuchen Sie mein ",
  picture: "url(../intro_pic.png)",
};

export const portfolioItems = [
  {
    id: 1,
    icon: "url(../app_icon_DDC.png)",
    name: "Dino Dome City",
    tech: "Kotlin",
    link: "https://play.google.com/store/apps/details?id=com.steffriedrichs.dinodomecity",
    button: "Play Store",
  },
  {
    id: 2,
    icon: "url(../bird_pairs_icon.png)",
    name: "Bird Pairs",
    tech: "Kotlin",
    link: "https://play.google.com/store/apps/details?id=com.steffriedrichs.birdpairs",
    button: "Play Store",
  },
  {
    id: 3,
    icon: "url(../cookie_pairs_icon.png)",
    name: "Cookie Pairs",
    tech: "Kotlin",
    link: "https://play.google.com/store/apps/details?id=com.steffriedrichs.cookiepairs",
    button: "Play Store",
  },
  {
    id: 4,
    icon: "url(../keksglueck_icon.png)",
    name: "KeksGlueck",
    tech: "Kotlin",
    link: "https://play.google.com/store/apps/details?id=com.steffriedrichs.keksglueck",
    button: "Play Store",
  },
  {
    id: 5,
    icon: "url(../husky_icon.png)",
    name: "Husky Pairs",
    tech: "Kotlin",
    link: "https://play.google.com/store/apps/details?id=com.steffriedrichs.huskypairs",
    button: "Play Store",
  },
  {
    id: 7,
    icon: "url(../project1-pic.png)",
    name: "Project 1",
    tech: "React",
    link: "https://project-1-react.netlify.app/",
    button: "Web-App",
    gitLink: "https://github.com/steffriedrichs/Project_1-React",
    gitButton: "git repo",
  },
  {
    id: 8,
    icon: "url(../r-paket.png)",
    name: "kangar00",
    tech: "R",
    link: "https://CRAN.R-project.org/package=kangar00",
    button: "CRAN",
    gitLink: "https://cran.r-project.org/web/packages/kangar00/kangar00.pdf",
    gitButton: "docs",
  },
];

export const items = [
  {
    text: `"Toller Service, super Produkt!"`,
    person: "- Zufriedener Kunde",
  },
  {
    text: `"Wieso habe ich dieses Angebot nicht schon viel früher gefunden"`,
    person: "- Stammkunde xy",
  },
  {
    text: `"Diese Firma hat unser dringendstes Problem gelöst und uns so viel Geld gespart."`,
    person: "- Chef der Firma X.",
  },
];

export const listSkills = [
  {
    id: 1,
    title: "Independent App Development",
    text: "Selbstständig - seit 2021",
    tech: "Kotlin, React, Android Studio, JavaScript, VSCode", // React Native, TypeScript
  },
  {
    id: 2,
    title: "Fullstack Developer",
    text: "Acio Networks GmbH Göttingen - 2020 - 2021",
    tech: "PHP, Laravel, JavaScript, React, SQL, PHPStorm",
  },
  {
    id: 3,
    title: "Frontend Developer",
    text: "Flowkey GmbH Berlin - 2018 - 2019",
    tech: "React, Javascript, Meteor, Cypress, GraphQL, XCode, Android Studio",
  },
  {
    id: 4,
    title: "Full Stack Web Development Bootcamp",
    text: "Ironhack Berlin - 2018",
    tech: "JavaScript, HTML, CSS, jQuery, Node.js, MongoDB, React, VSCode",
  },
  {
    id: 5,
    title: "Dr. sc. hum. Biostatistik / Bioinformatik",
    text: "Georg-August-Universität Göttingen - 2013 - 2017 ",
    tech: "R, HPC / Batch System / Shell Script, RStudio",
  },
  {
    id: 6,
    title: "M.Sc. Mathematik",
    text: "Georg-August-Universität Göttingen - 2011 - 2013",
    tech: "Java, C, SQL, R, Linux, Eclipse",
  },
];

export const boxItems = [
  {
    picture: "url(../react-code.png)",
    title: "React / JavaScript",
    text: "Professionelle responsive Websites und Web-Apps in modernem React sowie React Native Projekte.",
  },
  {
    picture: "url(../kotlin-code.png)",
    title: "Kotlin / Android",
    text: "Funktionale, benutzerfreundliche und attraktive native Android-Apps auf Basis von Kotlin.",
  },
];
