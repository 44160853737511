import * as React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const SectionContainerStyle = {
  margin: 0,
  width: "100%",
  height: "50px",
  display: "flex",
  justifyContent: "space-between",
};
const LinkStyle = {
  textDecoration: "none",
  color: "darkgrey",
  fontSize: "1.25rem",
  margin: "20px",
  fontWeight: "250",
  lineHeight: "50px",
};
const LinkStyleMobile = {
  textDecoration: "none",
  color: "darkgrey",
  fontSize: "1.25rem",
  margin: "5%",
};
const LinkBoxMobileStyle = {
  textAlign: "start",
  paddingLeft: "20px",
};
const mobileMenuStyle = {
  textAlign: "start",
  paddingLeft: "20px",
  paddingTop: "3px",
  fontSize: "32px",
  color: "darkgrey",
  cursor: "pointer",
};
const mobileCloseXStyle = {
  textAlign: "start",
  paddingLeft: "20px",
  paddingTop: "10px",
  fontSize: "20px",
  color: "darkgrey",
  cursor: "pointer",
};
const logoLinkStyle = {
  height: "20px",
  width: "20px",
  backgroundSize: "cover",
  cursor: "pointer",
  marginTop: "20px",
  marginRight: "25px",
};

/* TO DO: add mobile drop-down menu icon*/
function HeaderSection({ links }) {
  const [menuOpened, setMenuOpened] = useState(false);
  const [isHover, setIsHover] = useState(0);

  const handleMouseEnter = (id) => {
    setIsHover(id);
  };
  const handleMouseLeave = () => {
    setIsHover(0);
  };

  return (
    <div style={SectionContainerStyle}>
      <div>
        {window.innerWidth > 800 ? (
          links.pageLinks.map((link) => (
            <Link
              key={link.id}
              style={
                isHover === link.id
                  ? { ...LinkStyle, ...{ color: "black" } }
                  : LinkStyle
              }
              to={link.to}
              onMouseEnter={() => handleMouseEnter(link.id)}
              onMouseLeave={handleMouseLeave}
            >
              {link.text}
            </Link>
          ))
        ) : (
          <>
            <div
              style={menuOpened ? mobileCloseXStyle : mobileMenuStyle}
              onClick={() => setMenuOpened(!menuOpened)}
            >
              {menuOpened ? "\u2715" : "\u{2261}"}
            </div>
            {menuOpened ? (
              <div style={LinkBoxMobileStyle}>
                {links.pageLinks.map((link) => (
                  <p key={link.id}>
                    <Link style={LinkStyleMobile} to={link.to}>
                      {link.text}
                    </Link>
                  </p>
                ))}
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
      <div style={{ display: "flex" }}>
        {links.personalLinks.map((link) => (
          <a
            key={link.id}
            href={link.link}
            target={"_blank"}
            rel={"noreferrer"}
          >
            <div
              style={{ ...logoLinkStyle, ...{ backgroundImage: link.logo } }}
            ></div>
          </a>
        ))}
      </div>
    </div>
  );
}

export default HeaderSection;
